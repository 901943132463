import { useState } from "react";
import axios from "axios";
const useApp = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [password, setPassword] = useState("");
    const [params, setParams] = useState({
        limit: 100,
        search: "",
        start: "",
    });
    const [total, setTotal] = useState(0);
    const [records, setRecords] = useState([]);
    const getScreenshots = (params) => {
        axios.get("https://api.imclearning.com/api/lead/get-screenshots", {
            params: {
                ...params
            }
        }).then((res) => {
            const { data: result } = res;
            if (result?.status === 1) {
                setIsLoggedIn(true);
                const { count, rows } = result?.data?.data;
                setTotal(count);
                setRecords([...rows]);
            }
        })
    }
    const handleFilterEmployee = (value) => {
        const tempParams = { ...params, search: value };
        setParams({ ...tempParams });
        getScreenshots(tempParams);
    }
    const handleLogin = () => {
        getScreenshots(params);
    }
    const getImageSource = (path) => {
        return `https://api.imclearning.com/images/${path}`
    }
    return {
        isLoggedIn,
        password,
        setPassword,
        handleLogin,
        records,
        total,
        getImageSource,
        handleFilterEmployee,
        reload: () => getScreenshots(params)
    }
}
export default useApp;

