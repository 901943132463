import "./assets/stylesheet.scss";
import { Select, DatePicker, Input, Button, Image, Typography, Empty } from 'antd';
import {
  ReloadOutlined
} from '@ant-design/icons';
import useApp from "./useApp";
import moment from "moment";
const { Text, Title } = Typography;
function App() {
  const { isLoggedIn, password, setPassword, handleLogin, records, total, getImageSource, handleFilterEmployee, reload } = useApp();
  return (
    <>
      {
        isLoggedIn
          ?
          <>
            <div className="container">
              <header className='header'>
                <img width={60} src="https://leads.imclearning.com/lm/style/logo-dark.png" />
                <div className="header-action">
                  <Select
                    style={{
                      width: 150,
                    }}
                    placeholder="Select Employee"
                    showSearch
                    allowClear
                    onChange={(value) => handleFilterEmployee(value)}
                    options={employees_enums}
                  />
                  <DatePicker onChange={() => { }} />
                  <Button onClick={reload} icon={<ReloadOutlined />}>Reload</Button>
                </div>
              </header>
              <main className="main-body">
                {
                  records && records.map((item, index) => (
                    <div key={`Image Gallery ${index}`} className="screenshot-tile">
                      <Image
                        fallback="https://cpworldgroup.com/wp-content/uploads/2021/01/placeholder.png"
                        // width={200}
                        height={200}
                        src={getImageSource(item.path)} />
                      <div className="description">
                        <Title level={5}>{item.email}</Title>
                        <Text>{moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a")}</Text>
                      </div>
                    </div>
                  ))
                }
                {
                  records.length === 0
                    ?
                    <div className="no-data">
                      <Empty />
                    </div>
                    :
                    null
                }
              </main>
            </div>

          </>
          :
          <div className="authorization-container">
            <img width={120} src="https://leads.imclearning.com/lm/style/logo-dark.png" />
            <Input.Password
              style={{ width: 300 }}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password" />
            <Button onClick={handleLogin} disabled={!(password === "imc@2024$$")} type="primary">Sign In</Button>
          </div>
      }

    </>
  );
}

export default App;

const employees_enums = [
  { label: "Malik Ayaz", value: "malikayaz-pk@imcinstitute.ae" },
  { label: "Fasih", value: "fasih-pk@imcinstitute.ae" },
  { label: "Sikander Basit", value: "sikander-pk@imcinstitute.ae" },
  { label: "Tehniyat", value: "tehniyat-pk@imcinstitute.ae" },
  { label: "Leena", value: "leena-pk@imcinstitute.ae" },
  { label: "Fahad", value: "fahad-pk@imcinstitute.ae" },
  { label: "Shehroz", value: "shehroz-pk@imcinstitute.ae" },
  { label: "Sumaika", value: "sumaika-pk@imcinstitute.ae" },
  { label: "Sumaiya", value: "sumaiya-pk@imcinstitute.ae" },
  { label: "Saba Israr", value: "saba-pk@imcinstitute.ae" },
  { label: "Rafia", value: "rafia-pk@imcinstitute.ae" },
  { label: "Saif", value: "saif-pk@imcinstitute.ae" },
  { label: "Waqas", value: "waqas-pk@imcinstitute.ae" },
  { label: "Hiba", value: "hiba@imcinstitute.ae" },
  { label: "Ramsha", value: "ramsha@imcinstitute.ae" },
  { label: "Rufu Henry", value: "rufus-pk@imcinstitute.ae" },
  { label: "Kiran Cyndrella", value: "kiran-pk@imcinstitute.ae" },
  { label: "Mahvish", value: "mahvish-pk@imcinstitute.ae" },
  { label: "Alvina", value: "alvina-pk@imcinstitute.ae" },
  { label: "Ifrah", value: "ifrah-pk@imcinstitute.ae" },
  { label: "Samreen", value: "samreen-pk@imcinstitute.ae" },
]

